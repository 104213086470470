import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class GradeService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/grade';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }
    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }
    delete(gradeId) {
        let url = `${this.#api}/${gradeId}`
        return apiService.delete(url)
    }
    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }
    getGradeByIds(data={}) {
        let url = `${this.#api}/get-by-ids`;
        let param = {
            params: data
        }
        return apiService.query(url,param);
    }
    getByExamSession(examSessionId, data){
        let url = `${this.#api}/${examSessionId}/get/by/exam-session`;
        return apiService.get(url);
    }
}
