import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class LocationService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/location';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    all(data={}){

        let url = `${this.#api}/get/all`;
        let param ={
            params: data
        }
        return apiService.query(url,param)
    }

    getByScore(scoreId){
        let url = `${this.#api}/${scoreId}/get/by/score`
        return apiService.get(url)
    }

    getAllStatusByScore(scoreId){
        let url = `${this.#api}/${scoreId}/get/all-status/by-score`
        return apiService.get(url)
    }

    sort(data=[]){
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }

    getByExamSession(examSessionId){
        let url = `${this.#api}/${examSessionId}/get/by/exam-session`;
        return apiService.get(url);
    }
}