import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class EnrolmentYearService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/enrolment-year';
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url)
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    uniqueEnrolmentYear()
    {
        let url = `${this.#api}/unique`;
        return apiService.get(url);
    }
    currentActiveYear(id) {
        let url = `${this.#api}/current/get`
        return apiService.get(url)
    }
}